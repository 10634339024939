import React from 'react'
import { Button, Form, Input, TextArea, Message } from 'semantic-ui-react'
import isEmail from 'validator/lib/isEmail';


class formStayInformed extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      fname: '',
      lname: '',
      email: '',
      company: '',
      message: '',
      formProcessing: false,
      MessageSuccessfullySent: false,
      errors: []
    }

    this.formSubmit = this.formSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  onChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  formSubmit(event) {
    event.preventDefault()

    var errors = this.FormValidation()
    this.setState({errors})

    if(errors.length === 0){
      // Form is valid

      this.setState({formProcessing: true});

      const url = "https://ygwl3tze49.execute-api.us-east-1.amazonaws.com/production/contact/form"
      var data = {
        data: {
          FirstName: this.state.fname,
          LastName: this.state.lname,
          Company: this.state.company,
          Email: this.state.email,
          Message: this.state.message,
        }
      }

      fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data), // body data type must match "Content-Type" header
      })

        .then(function(response) {
          console.log('successful!!!')
          console.log(response)

          // Clear Form Values
          this.setState({
            fname: '',
            lname: '',
            email: '',
            company: '',
            message: '',
            formProcessing: false,
            MessageSuccessfullySent: true,
          })

      }.bind(this))

        .catch(function(err) {
          this.setState({formProcessing: false})
          console.log('error')
          console.log(err)
      }.bind(this))

    }
  }

  FormValidation() {
    let errors = []

    if (this.state.fname === ''){
      errors.push({ key: 'fname', message: 'Please provide a your first name.' });
    }

    if (this.state.lname === ''){
      errors.push({ key: 'fname', message: 'Please provide a your last name.' });
    }

    if (this.state.email === ''){
      errors.push({ key: 'email', message: 'You need to include an email.' });
    }

    if (!isEmail(this.state.email)){
      errors.push({ key: 'email', message: 'Please provide a valid email address.' });
    }


    if (this.state.company === ''){
      errors.push({ key: 'company', message: 'Please provide your company.' });
    }

    return errors
  }


  isFieldError(key, errorArray){
    var result = false

    for (var i = 0; i < errorArray.length; i++) {
      if (errorArray[i].key === key) {
        result = true
        break
      }
    }
    return result
  }

  render() { 
    return (
          <Form
            error={this.state.errors.length > 0}
            success={this.state.MessageSuccessfullySent}
            loading={this.state.formProcessing}
          >

            <Form.Field>
              <h3>Provide your contact information to stay informed on the latest news from Biolark.</h3>
            </Form.Field>

            <Form.Group widths='equal'>

              <Form.Field
                error={this.isFieldError('fname', this.state.errors)}
              >
              <label>First Name</label>
                <Input
                  name='fname'
                  placeholder='First Name'
                  value={this.state.fname}
                  onChange={this.onChange}
                  />
              </Form.Field>

              <Form.Field
                error={this.isFieldError('lname', this.state.errors)}
              >
              <label>Last Name</label>
                <Input
                  name='lname'
                  placeholder='First Name'
                  value={this.state.lname}
                  onChange={this.onChange}
                  />
              </Form.Field>

            </Form.Group>

            <Form.Field
              error={this.isFieldError('company', this.state.errors)}
            >
            <label>Company</label>
              <Input
                name='company'
                placeholder='Company'
                value={this.state.company}
                onChange={this.onChange}
                />
            </Form.Field>

            <Form.Field
              error={this.isFieldError('email', this.state.errors)}
            >
            <label>Email</label>
              <Input
                name='email'
                placeholder='email@example.com'
                value={this.state.email}
                onChange={this.onChange}
                />
            </Form.Field>


            <Form.Field
              error={this.isFieldError('message', this.state.errors)}
            >
            <label>Message</label>
              <TextArea
                name='message'
                placeholder='Message'
                value={this.state.message}
                onChange={this.onChange}
                />
            </Form.Field>



            <Button
              primary
              type='submit'
              content='Keep me informed'
              onClick={this.formSubmit}
              disabled={(!(this.state.fname && this.state.lname && this.state.company && this.state.email)) || this.state.formProcessing}
              />

          <Message
            success={true}
            positive={true}
            header='Successfully Sent.'
            content='You have successfully sent your message.  We will get back to you as soon as possible.'
          />
          <Message
            error={true}
            header='Something is wrong.'
            list={this.state.errors.map(x => x.message)}
          />

          </Form>

    )
  }
}

export default formStayInformed