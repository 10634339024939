import React from "react"
import { Helmet } from "react-helmet"
import logo from "../assets/images/logo-purple.svg" // Tell Webpack this JS file uses this image
import FormStayInformed from '../components/FormStayInformed'
import {Grid, Segment} from 'semantic-ui-react'
import Layout from "../components/layouts"



class index extends React.Component {
  // The entire calculator pages use the same template and have the same basic authorization.
  render() {
    return (
      <Layout>
        <div id="homepage">
          <Helmet>
            <title>Biolark</title>
            <meta
              name="description"
              content="Biolark"
            />
          </Helmet>
          <img className="logo" src={logo} alt="Logo" />


          <Grid centered verticalAlign='middle'>
            <Grid.Column mobile={16} tablet={10} computer={8}>
              <Segment>
                <FormStayInformed/>
              </Segment>
            </Grid.Column>
          </Grid>

        </div>
      </Layout>
    )
  }
}
export default index