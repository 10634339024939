import React from "react"
import PropTypes from "prop-types"
import 'semantic-ui-css/semantic.min.css'
import "../../styles/main.scss"


class Template extends React.Component {

  render() {
    const { children } = this.props;

    return (
      <div>
        {children}
      </div>
    )
  }
}

Template.propTypes = {
  children: PropTypes.array,
};

export default Template;
